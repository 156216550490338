import { WALLETS } from 'data/supportedWallet';

interface Network {
	name: string;
	image: string;
	value: string;
}

export interface TotalTvl {
	month: string;
	Value: number;
}

export interface Exchanger {
	name: string;
	image: string;
	value: string;
	estApy: string;
	totalUSDC: number | string;
	totalTvl: TotalTvl[];
	network?: Network[];
	aptosContractAddress?: string;
	tvl?: string;
	depositDisabled?: boolean;
	contractAddress?: string;
}

export interface Vault {
	id: number;
	name: string;
	estApy: string;
	currency: string;
	type: string;
	shortDescription: string;
	logo: string;
	darkLogo: string;
	exchanger: Exchanger[];
	token: {
		name: string;
		icon: string;
		alt: string;
		address: string;
		decimalValue: number;
		maxAllowed: number;
	}[];
	isActive: boolean;
	isAptos?: boolean;
	wallets: WALLETS[];
}

// Define the data using the defined types
export const VAULTS: { [keys: string]: Vault[] } = {
	'Move-EVM': [
		{
			id: 0,
			name: 'Atlas',
			type: 'Earn Max points with high capital efficiency',
			shortDescription: 'Marketmaking agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			currency: '$',
			exchanger: [
				{
					name: 'Movement-labs',
					value: 'Movement-labs',
					image: '/crypto/movement.svg',
					estApy: '28%',
					totalUSDC: '200m',
					tvl: '400k',
					totalTvl: [
						{ month: 'Jan', Value: 0 },
						{ month: 'Feb', Value: 0 },
						{ month: 'Mar', Value: 0 },
						{ month: 'Apr', Value: 0 },
						{ month: 'May', Value: 0 },
						{ month: 'June', Value: 0 },
						{ month: 'July', Value: 0 },
					],

					contractAddress:
						'0xfe2ce82de9f4083a3db23e1b15c55a83642e0aa6', //'0xaFE0732F985659986Cc3f27AeF76f419BAae5Cde',
				},
			],
			token: [
				{
					address: '0xaFE0732F985659986Cc3f27AeF76f419BAae5Cde', // 0x833589fcd6edb6e08f4c7c32d4f71b54bda02913 //'0x846B2EaEC7D9A21cf073F4dDa79C6aEa0919c867',
					decimalValue: 6,
					maxAllowed: 1000000,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: true,
			isAptos: false,
			wallets: [
				WALLETS.metamask,
				WALLETS.razor,
				WALLETS.nightly,
				WALLETS.okx,
			],
		},
		{
			id: 1,
			name: 'Ganesh',
			estApy: '40%',
			currency: '$',
			type: 'Sentiment vault',
			shortDescription: 'Sentiment vault',
			logo: '/images/ganesh-brand.png',
			darkLogo: '/images/Ganesh-white.png',
			exchanger: [
				{
					name: 'Hyperliquid',
					value: 'Hyperliquid',
					image: '/images/exchange/hyperliquid.png',
					estApy: '40%',
					totalUSDC: '100k',
					totalTvl: [
						{ month: 'Jan', Value: 0 },
						{ month: 'Feb', Value: 0 },
						{ month: 'Mar', Value: 0 },
						{ month: 'Apr', Value: 0 },
						{ month: 'May', Value: 0 },
						{ month: 'June', Value: 0 },
						{ month: 'July', Value: 0 },
					],
					network: [
						{
							name: 'Arbitrum',
							value: 'Arbitrum',
							image: '/images/arbitrum.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x05C3aCf16d546cd3c5D8239A9B03e959890DC32C', // update the address
					decimalValue: 18,
					maxAllowed: 1000000,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: false,
			isAptos: false,
			wallets: [
				WALLETS.metamask,
				WALLETS.razor,
				WALLETS.nightly,
				WALLETS.okx,
			],
		},
	],
	'Move-Porto': [
		{
			id: 0,
			name: 'Atlas',
			type: 'Earn Max points with high capital efficiency',
			shortDescription: 'Marketmaking agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			currency: 'MOVE',
			exchanger: [
				{
					name: 'Movement-labs',
					value: 'Movement-labs',
					image: '/crypto/movement.svg',
					estApy: '28%',
					totalUSDC: '200m',
					aptosContractAddress:
						'0x42f2d2f2387d0c99febf66b862173d131354a4a3e34363568a3b58757229946a',

					tvl: '400k',
					totalTvl: [
						{ month: 'Jan', Value: 0 },
						{ month: 'Feb', Value: 0 },
						{ month: 'Mar', Value: 0 },
						{ month: 'Apr', Value: 0 },
						{ month: 'May', Value: 0 },
						{ month: 'June', Value: 0 },
						{ month: 'July', Value: 0 },
					],

					contractAddress:
						'0xfe2ce82de9f4083a3db23e1b15c55a83642e0aa6', //'0xaFE0732F985659986Cc3f27AeF76f419BAae5Cde',
				},
			],
			token: [
				{
					address: '0xaFE0732F985659986Cc3f27AeF76f419BAae5Cde', // 0x833589fcd6edb6e08f4c7c32d4f71b54bda02913 //'0x846B2EaEC7D9A21cf073F4dDa79C6aEa0919c867',
					decimalValue: 6,
					maxAllowed: 100000000,
					name: 'MOVE',
					icon: '/images/crypto/move.png',
					alt: 'MOVE',
				},
			],
			isActive: true,
			isAptos: true,
			wallets: [
				WALLETS.metamask,
				WALLETS.razor,
				WALLETS.nightly,
				WALLETS.okx,
			],
		},
		{
			id: 1,
			name: 'Ganesh',
			estApy: '40%',
			currency: 'MOVE',
			type: 'Sentiment vault',
			shortDescription: 'Sentiment vault',
			logo: '/images/ganesh-brand.png',
			darkLogo: '/images/Ganesh-white.png',
			exchanger: [
				{
					name: 'Hyperliquid',
					value: 'Hyperliquid',
					aptosContractAddress:
						'0x42f2d2f2387d0c99febf66b862173d131354a4a3e34363568a3b58757229946a',

					image: '/images/exchange/hyperliquid.png',
					estApy: '40%',
					totalUSDC: '100k',
					totalTvl: [
						{ month: 'Jan', Value: 0 },
						{ month: 'Feb', Value: 0 },
						{ month: 'Mar', Value: 0 },
						{ month: 'Apr', Value: 0 },
						{ month: 'May', Value: 0 },
						{ month: 'June', Value: 0 },
						{ month: 'July', Value: 0 },
					],
					network: [
						{
							name: 'Arbitrum',
							value: 'Arbitrum',
							image: '/images/arbitrum.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x05C3aCf16d546cd3c5D8239A9B03e959890DC32C', // update the address
					decimalValue: 18,
					maxAllowed: 1000000,
					name: 'USDC',
					icon: '/images/crypto/move.png',
					alt: 'MOVE',
				},
			],
			isActive: false,
			isAptos: true,
			wallets: [
				WALLETS.metamask,
				WALLETS.razor,
				WALLETS.nightly,
				WALLETS.okx,
			],
		},
	],
	Avalanche: [
		{
			id: 0,
			name: 'Atlas',
			currency: '$',
			type: 'Earn Max points with high capital efficiency',
			shortDescription: 'Marketmaking agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			exchanger: [
				{
					name: 'Enclave markets',
					value: 'Enclave markets',
					image: '/images/exchange/enclave.png',
					estApy: '28%',
					totalUSDC: '200m',
					tvl: '400k',
					contractAddress:
						'0xa205D1753352779A8B559A2173F07D9F72eEEf6d',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					network: [
						{
							name: 'Avalanche',
							value: 'Avalanche',
							image: '/crypto/avax.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // update the address
					decimalValue: 6,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: true,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
		{
			id: 1,
			name: 'Ganesh',
			currency: '$',
			estApy: '40%',
			type: 'Sentiment vault',
			shortDescription: 'Sentiment vault',
			logo: '/images/ganesh-brand.png',
			darkLogo: '/images/Ganesh-white.png',
			exchanger: [
				{
					name: 'Hyperliquid',
					value: 'Hyperliquid',
					image: '/images/exchange/hyperliquid.png',
					estApy: '40%',
					totalUSDC: '100k',
					contractAddress:
						'0xa205D1753352779A8B559A2173F07D9F72eEEf6d',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					network: [
						{
							name: 'Arbitrum',
							value: 'Arbitrum',
							image: '/images/arbitrum.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E', // update the address
					decimalValue: 18,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: false,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
	],
	Base: [
		{
			id: 0,
			name: 'Atlas',
			currency: '$',
			type: 'Earn Max points with high capital efficiency', // "High-Risk, High-Reward",
			shortDescription: 'Marketmaking agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			exchanger: [
				{
					name: 'Bsx',
					value: 'bsx',
					image: '/images/exchange/bsx.png',
					estApy: '28%',
					totalUSDC: '200m',
					tvl: '400k',
					contractAddress:
						'0xF888565F6E898698be8E64c219Ca5ab9c1Eee1cB',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					depositDisabled: true,
					network: [
						{
							name: 'Base',
							value: 'base',
							image: '/crypto/base.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x833589fcd6edb6e08f4c7c32d4f71b54bda02913', // update the address
					decimalValue: 6,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: true,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
		{
			id: 1,
			name: 'Ganesh',
			currency: '$',
			estApy: '40%',
			type: 'Sentiment vault',
			shortDescription: 'Sentiment vault',
			logo: '/images/ganesh-brand.png',
			darkLogo: '/images/Ganesh-white.png',
			exchanger: [
				{
					name: 'Hyperliquid',
					value: 'Hyperliquid',
					image: '/images/exchange/hyperliquid.png',
					estApy: '40%',
					totalUSDC: '100k',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					network: [
						{
							name: 'Arbitrum',
							value: 'Arbitrum',
							image: '/images/arbitrum.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x05C3aCf16d546cd3c5D8239A9B03e959890DC32C', // update the address
					decimalValue: 18,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: false,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
	],
	'Binance-Smart-Chain': [
		{
			id: 0,
			name: 'Atlas',
			currency: '$',
			type: 'Earn Max points with high capital efficiency', // "High-Risk, High-Reward",
			shortDescription: 'Marketmaking agent',
			logo: '/images/atlas-brand.png',
			darkLogo: '/images/Atlas-white.png',
			estApy: '28%',
			exchanger: [
				{
					name: 'Binance-Smart-Chain',
					value: 'Binance-Smart-Chain',
					image: '/crypto/binance.svg',
					estApy: '28%',
					totalUSDC: '200m',
					tvl: '400k',
					contractAddress:
						'0x5EDdb64aC58E070F2822Df5c2B41f54f83545F76',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					network: [],
				},
			],
			token: [
				{
					address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // update the address
					decimalValue: 18,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: true,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
		{
			id: 1,
			name: 'Ganesh',
			currency: '$',
			estApy: '40%',
			type: 'Sentiment vault',
			shortDescription: 'Sentiment vault',
			logo: '/images/ganesh-brand.png',
			darkLogo: '/images/Ganesh-white.png',
			exchanger: [
				{
					name: 'Binance-Smart-Chain',
					value: 'bnb',
					image: '/crypto/binance.svg',
					estApy: '28%',
					totalUSDC: '200m',
					tvl: '400k',
					contractAddress:
						'0x5EDdb64aC58E070F2822Df5c2B41f54f83545F76',
					totalTvl: [
						{ month: 'Dec', Value: 0.1 },
						{ month: 'Jan', Value: 0.4 },
						{ month: 'Feb', Value: 1 },
						{ month: 'Mar', Value: 10 },
						{ month: 'Apr', Value: 30 },
						{ month: 'May', Value: 50 },
						{ month: 'June', Value: 82 },
						{ month: 'July', Value: 110 },
						{ month: 'Aug', Value: 140 },
					],
					network: [
						{
							name: 'Bnb',
							value: 'binance-smart-chain',
							image: '/crypto/binance.svg',
						},
					],
				},
			],
			token: [
				{
					address: '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // update the address
					decimalValue: 18,
					maxAllowed: 100,
					name: 'USDC',
					icon: '/images/crypto/usdc.png',
					alt: 'USDC',
				},
			],
			isActive: false,
			isAptos: false,
			wallets: [WALLETS.metamask],
		},
	],
};
