import React, { useCallback, useEffect, useState } from 'react';
import { AmountInputBox } from 'pages/Landing/components/AmountInputBox';
import styled from '@emotion/styled';
import { Box, Button, Typography } from '@mui/material';
import CustomDropdown from 'pages/Landing/components/CustomDropdown';
import { vaultActions } from 'store/vaults';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { Vault, VAULTS } from 'data/Vaults';
import { ItemCard } from 'pages/Landing/components/ItemCard';
import { useNetwork } from 'hooks/useNetwork';
import { getErc20Contract } from 'utils/contractHelpers';
import { formatUnits, parseUnits } from 'ethers/lib/utils';
import { getDepositContract } from 'utils/depositContractHelpers';
import { toast } from 'react-toastify';
import { userDeposit } from 'apis';
import { useEthersProvider } from 'hooks/useEtherProvider';
import { useEthersSigner } from 'hooks/useEtherSigner';
import { useAccount } from 'wagmi';
import { appActions } from 'store/app';
import { fetchIpInformation } from 'utils/geolocation';
import { NotServedModal } from 'pages/NotServedModal';
import { getErrorMessage } from 'data/errors';
import { useAptosContractBalance } from 'hooks/useAptosContractBalance';
import { MOVE_VALUE } from 'data/constant';
import {
	fetchAgentPoolUserBalance,
	getAptosBalance,
} from 'utils/getAptosBalance';

const Row = styled('div')`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
`;

const FlexRow = styled('div')`
	display: flex;
	flex-direction: row;
`;

const Text = styled(Typography)`
	font-weight: 500;

	:hover {
		color: #235ee1;
		cursor: pointer;
	}
`;

const Column = styled('div')`
	display: flex;
	flex-direction: column;
`;

const SpaceBetween = styled(Column)`
	height: 100%;
	justify-content: space-between;
`;

const StyledRow = styled('div')`
	display: flex;
	flex: 1 1;
	flex-direction: row;
	flex-wrap: nowrap;
	width: 100%;
	column-gap: 16px;
`;

export const DepositPanel = () => {
	const [isBlocking, setIsBlocking] = useState<boolean>(false);
	const [loading, setLoading] = useState(false);
	const [allowance, setAllowance] = useState('0');
	const [balance, setBalance] = useState('0');
	const [contractBal, setContractBal] = useState('0');
	const [amount, setAmount] = useState('');
	const [aptosBalance, setAptosBalance] = useState('0');
	const { address: account, isConnected } = useAccount();
	const dispatch = useDispatch();
	const appState = useSelector((state: RootState) => state.app);
	const appVault = useSelector((state: RootState) => state.vault);
	const selectedVault = useSelector((state: RootState) => state.vault);
	const selectedAsset = selectedVault.token.find((d) => d.name === 'USDC');
	const selectedExchange = selectedVault.exchanger.find(
		(d) => d.name === appVault.exchange,
	);
	const [aptosContractBalance, setAptosContractBalance] = useState('0');
	useNetwork();
	const provider: any = useEthersProvider();
	const signer: any = useEthersSigner();
	const networkDetails = {
		disabled:
			appState.selectedNetwork !== 'Move-EVM' &&
			parseFloat(amount) + parseFloat(contractBal) >
				(selectedAsset?.maxAllowed || 100),
		message: `0-${
			selectedAsset?.maxAllowed || '100'
		} Currently in beta testing`,
	};
	const { isAptosConnected, signAndSubmitTransaction, aptosAccount } =
		useAptosContractBalance();
	const bal = selectedVault.isAptos
		? aptosBalance?.toString()
		: parseFloat(parseFloat(balance).toFixed(2)).toLocaleString();
	const contractBalance = selectedVault.isAptos
		? aptosContractBalance
		: parseFloat(parseFloat(contractBal).toFixed(2)).toLocaleString();
	const isAccountConnected = isConnected || isAptosConnected;

	const getAptosAccountBalance = useCallback(async () => {
		const res = await getAptosBalance(aptosAccount?.address || '');
		setAptosBalance(res);
	}, [aptosAccount]);

	const getAptosContractBalance = useCallback(async () => {
		const res = await fetchAgentPoolUserBalance(
			selectedExchange?.aptosContractAddress || '',
			aptosAccount?.address || '',
		);
		setAptosContractBalance(res);
	}, [aptosAccount?.address, selectedExchange?.aptosContractAddress]);

	const onAptosDeposit = async () => {
		if (parseFloat(amount) > parseFloat(aptosBalance)) {
			return toast(
				'Wallet does not have sufficient funds to deposit.' || {
					type: 'error',
				},
			);
		}

		try {
			setLoading(true);
			if (selectedExchange && selectedExchange.aptosContractAddress) {
				const res = await signAndSubmitTransaction({
					payload: {
						function: `${selectedExchange?.aptosContractAddress}::agent_pool::deposit`,
						typeArguments: ['0x1::aptos_coin::AptosCoin'],
						functionArguments: [
							(parseFloat(amount) * MOVE_VALUE)
								.toFixed(0)
								.toString(),
							aptosAccount?.address,
						],
					},
				});

				await new Promise((resolve) => setTimeout(resolve, 3000));

				if (res.status === 'Approved') {
					await userDeposit({
						contract: selectedExchange.contractAddress || '',
						address: account || '',
						amount,
						token: 'USDC',
						eventType: 'deposit',
					});
					await getAptosContractBalance();
					await getAptosAccountBalance();
					toast(
						'Successfully staked into vault contract' || {
							type: 'success',
						},
					);
				}
			}
		} catch (error) {
			toast(
				'Something is not right' || {
					type: 'error',
				},
			);
		} finally {
			setLoading(false);
		}
	};

	const fetchAllowance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					provider.getSigner(account).connectUnchecked(),
				);
				const assetAllowance = await assetContract.allowance(
					account,
					selectedExchange?.contractAddress,
				);
				setAllowance(
					formatUnits(assetAllowance, selectedAsset?.decimalValue) ||
						'0',
				);
			} else {
				setAllowance('0');
			}
		} catch (e) {
			//
		}
	}, [
		account,
		provider,
		selectedAsset?.address,
		selectedAsset?.decimalValue,
		selectedExchange?.contractAddress,
	]);

	const fetchBalance = useCallback(async () => {
		try {
			if (account) {
				const assetContract = getErc20Contract(
					selectedAsset?.address || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);
				const assetBalance = await assetContract.balanceOf(account);
				setBalance(
					formatUnits(assetBalance, selectedAsset?.decimalValue),
				);
			} else {
				setBalance('0');
			}
		} catch (e) {
			//
		}
	}, [account, signer, selectedAsset?.address, selectedAsset?.decimalValue]);

	const fetchContractBalance = useCallback(async () => {
		try {
			if (account) {
				dispatch(
					appActions.setLoading({
						loading: true,
						message: 'Please Wait...',
					}),
				);
				const contract = getDepositContract(
					selectedExchange?.contractAddress || '',
					signer?.provider.getSigner(account).connectUnchecked(),
				);

				// fetch balance
				const userContractBalance = await contract.balanceOf(account);
				const userContractBal = await contract.convertToAssets(
					userContractBalance,
				);
				setContractBal(
					formatUnits(userContractBal, selectedAsset?.decimalValue) ||
						'0',
				);
			} else {
				setContractBal('0');
			}
		} catch (e) {
			//
		} finally {
			dispatch(appActions.setLoading({ loading: false, message: '' }));
		}
	}, [
		account,
		dispatch,
		selectedExchange?.contractAddress,
		signer?.provider,
		selectedAsset?.decimalValue,
	]);

	const onDeposit = async () => {
		try {
			setLoading(true);

			let tempBal = '0';
			const assetContract = getErc20Contract(
				selectedAsset?.address || '',
				signer?.provider.getSigner(account).connectUnchecked(),
			);
			const assetBalance = await assetContract.balanceOf(account);
			tempBal = formatUnits(assetBalance, selectedAsset?.decimalValue);

			if (parseFloat(tempBal) < parseFloat(amount)) {
				return toast(
					'Wallet does not have sufficient funds to deposit.' || {
						type: 'error',
					},
				);
			}

			if (appState.selectedNetwork === 'Base') {
				const isBlocked = await fetchIpInformation();
				if (isBlocked) {
					setIsBlocking(true);
					return;
				}
			}

			const contract = getDepositContract(
				selectedExchange?.contractAddress || '',
				signer?.provider.getSigner(account).connectUnchecked(),
			);

			if (parseFloat(allowance) < parseFloat(amount)) {
				const tx = await assetContract.approve(
					selectedExchange?.contractAddress || '',
					parseUnits(amount.toString(), selectedAsset?.decimalValue),
				);
				await assetContract.provider.waitForTransaction(tx.hash);
				const updatedAllowance = await assetContract.allowance(
					account,
					selectedExchange?.contractAddress || '',
				);

				if (updatedAllowance < amount) {
					toast('Please allow USDC spending', {
						type: 'error',
					});
					return;
				}
			}
			const dpt = await contract.deposit(
				parseUnits(amount, selectedAsset?.decimalValue),
				account,
			);
			await dpt.wait();
			await userDeposit({
				contract: selectedExchange?.contractAddress || '',
				address: account || '',
				amount,
				token: 'USDC',
				eventType: 'deposit',
			});

			toast('Successfully staked into vault contract', {
				type: 'success',
			});
			setAmount('0');
		} catch (e: any) {
			toast(
				getErrorMessage(e?.error?.code || e?.code, e) ||
					'Something went wrong',
				{
					type: 'error',
				},
			);
		} finally {
			fetchContractBalance();
			fetchBalance();
			fetchAllowance();
			setLoading(false);
		}
	};

	const onPercentClick = (txt: number) => {
		setAmount(((parseFloat(bal) * txt) / 100).toString());
	};

	useEffect(() => {
		if (!selectedVault.isAptos) {
			fetchContractBalance();
			fetchAllowance();
			fetchBalance();
		} else {
			getAptosContractBalance();
			getAptosAccountBalance();
		}
	}, [
		fetchAllowance,
		fetchBalance,
		fetchContractBalance,
		getAptosAccountBalance,
		getAptosContractBalance,
		selectedVault.isAptos,
	]);

	return (
		<>
			<SpaceBetween>
				<Column>
					<AmountInputBox
						currency={{
							icon: selectedVault.token[0].icon,
							name: selectedVault.token[0].name,
						}}
						text={amount}
						onChange={setAmount}
						leftTxt={`${selectedVault.currency} ${bal}`}
						rightTxt={
							selectedVault.currency + ' ' + contractBalance
						}
					/>
					<Box height={16} />
					<Row>
						<Text onClick={() => onPercentClick(25)}>25%</Text>
						<Text onClick={() => onPercentClick(50)}>50%</Text>
						<Text onClick={() => onPercentClick(100)}>Max</Text>
					</Row>
					<Box height={10} />
					<StyledRow>
						{VAULTS[appState.selectedNetwork].map(
							(vault: Vault) => (
								<ItemCard key={vault.name} {...vault} />
							),
						)}
					</StyledRow>

					<Box height={16} />
					<CustomDropdown
						dataSource={selectedVault.exchanger.map((d) => {
							return {
								name: d.name,
								image: d.image,
								value: d.value,
							};
						})}
						value={selectedVault.exchange}
						onChange={(d) =>
							dispatch(
								vaultActions.changeExchange(d.target.value),
							)
						}
						image={
							selectedVault.exchanger.find(
								(d) =>
									d.value.toLowerCase() ===
									selectedVault.exchange.toLowerCase(),
							)?.image || ''
						}
					/>
					<Box height={16} />
					{selectedVault.network && (
						<CustomDropdown
							dataSource={
								selectedVault.exchanger.find(
									(d) =>
										d.value.toLowerCase() ===
										selectedVault.exchange.toLowerCase(),
								)?.network || []
							}
							value={selectedVault.network}
							onChange={(d) =>
								dispatch(
									vaultActions.changeNetwork(d.target.value),
								)
							}
							image={
								selectedVault.exchanger.find(
									(d) => d.value === selectedVault.exchange,
								)?.image || ''
							}
						/>
					)}
					<hr />
					<Box height={5} />
					<FlexRow>
						<Typography color={'#666677'}>Deposit to: </Typography>
						<Box width={5} />
						<Typography> {selectedVault.name}</Typography>
					</FlexRow>
					<Box height={5} />
				</Column>
				<Column>
					<Button
						onClick={() => {
							if (!isAccountConnected) {
								dispatch(appActions.openDrawer(true));
							} else if (selectedExchange?.depositDisabled) {
								toast('Service is currently unavailable.', {
									type: 'default',
								});
							} else if (selectedVault.isAptos) {
								onAptosDeposit();
							} else {
								onDeposit();
							}
						}}
						disabled={
							loading ||
							//parseFloat(balance) < parseFloat(amount) ||
							parseFloat(amount || '0') === 0 ||
							networkDetails.disabled
							// || selectedExchange?.depositDisabled
						}
						sx={{
							height: 52,
						}}
						variant="contained"
						fullWidth
						color="primary"
					>
						<Typography
							variant={'button'}
							fontWeight="bold"
							py="6px"
							color={'#fff'}
							style={{ lineHeight: '120%' }}
						>
							{!isAccountConnected
								? 'Connect'
								: networkDetails.disabled
								? networkDetails.message
								: loading
								? 'Please wait...'
								: 'Deposit'}
						</Typography>
					</Button>
					<Box height={32} />
				</Column>
			</SpaceBetween>
			<NotServedModal
				open={isBlocking}
				onClose={() => setIsBlocking(false)}
			/>
		</>
	);
};
